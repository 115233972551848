import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Ending from "../components/ending"
import Layout from "../components/layout"
import Seo from "../components/seo"

type BlogIndexType = {
  data: GatsbyTypes.BlogIndexQueryQuery
  location: Location
}

const BlogIndex: React.FC<BlogIndexType> = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title ?? `Title`
  const posts = data?.allMarkdownRemark?.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Bio />
        <p>
          No blog posts found. Add markdown posts to &quot;content/blog&quot;
          (or the directory you specified for the
          &quot;gatsby-source-filesystem&quot; plugin in gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} fullWidth>
      <Bio />
      <Ending />
      <ol className="post-list-container">
        {posts.map(post => {
          const title = post.frontmatter?.title || post.fields?.slug
          const image = getImage(
            post.frontmatter?.image?.childImageSharp ?? null
          )

          return (
            <li key={post.fields?.slug} className="post-list-item-container">
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    {post.fields?.slug ? (
                      <Link to={post.fields?.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    ) : (
                      <span itemProp="headline">{title}</span>
                    )}
                  </h2>
                  <small>{post.frontmatter?.date}</small>
                </header>
                <section className="post-list-item-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        post.frontmatter?.description ?? post.excerpt ?? ``,
                    }}
                    itemProp="description"
                    className="post-list-item-description-text"
                  />
                  {image !== undefined &&
                    (post.fields?.slug ? (
                      <Link to={post.fields?.slug} itemProp="url">
                        <GatsbyImage image={image} alt="" />
                      </Link>
                    ) : (
                      <GatsbyImage image={image} alt="" />
                    ))}
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

type HeadType = { data: GatsbyTypes.BlogIndexQueryQuery }

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head: React.FC<HeadType> = ({ data }) => {
  const latestPathname = data.allMarkdownRemark.nodes[0].fields?.slug
  return (
    <Seo
      title={data?.site?.siteMetadata?.newsletterTitle ?? undefined}
      index={latestPathname ? { latestPathname: latestPathname } : {}}
    />
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
        newsletterTitle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { width: 100 }
                width: 600
                aspectRatio: 2
                transformOptions: { fit: COVER, cropFocus: CENTER }
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`

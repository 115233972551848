import * as React from "react"

const Ending = () => (
  <div className="ending-notice">
    <div className="ending-notice-borderbox">
      <div>あまねけ！ニュースレターは更新を終了しました。</div>
      <div>
        かたぎりあまねのコンテンツは引き続き
        <a href="https://ama.ne.jp/">あまねけ！</a>でお楽しみいただけます。
      </div>
    </div>
  </div>
)

export default Ending
